:root {
	--chakra-colors-gray-400: #A3A3A3;
	
  }
  
.custom-scrollbar.scroll-auto-hide {
	scrollbar-color: transparent transparent;
}

.custom-scrollbar.scroll-auto-hide:hover {
	scrollbar-color: var(
			--chakra-colors-gray-400,
			rgba(255, 255, 255 0.2)
		)
		transparent;
}

.custom-scrollbar {
	scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 18px;
	height: 18px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	border: 6px solid transparent;
	background: var(--chakra-colors-gray-400, rgba(255, 255, 255 0.2));
	border-radius: 10px;
	background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-corner {
	background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
	min-height: 30px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
	min-width: 30px;
}

.custom-scrollbar.scroll-auto-hide::-webkit-scrollbar-thumb {
	background: transparent;
	background-clip: padding-box;
}

.custom-scrollbar.scroll-auto-hide:hover::-webkit-scrollbar-thumb {
	background: var(--chakra-colors-gray-400, rgba(255, 255, 255 0.2));
	background-clip: padding-box;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background-clip: padding-box;
	border: 4px solid transparent;
}
@media (max-width: 768px) {
	.custom-scrollbar::-webkit-scrollbar {
		width: 1px;
		height: 1px;
	}
	
}